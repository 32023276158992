import * as React from "react";
import { Snackbar } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { removeMessage } from "./messagesSlice";

export const LowPriorityMessage = () => {
    const autoHideDuration = 5000; /* Milliseconds */
    const dispatch = useDispatch();
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [message, setMessage] = React.useState({});
    const handleSnackBarClose = () => {
        dispatch(removeMessage({ id: message.id, priority: `low` }));
        setSnackBarOpen(false);
    };
    const { lowPriority } = useSelector((state) => state.messages);

    React.useEffect(() => {
        if (lowPriority.length > 0) {
            setMessage(lowPriority[0]);
            setSnackBarOpen(true);
        }
    }, [lowPriority]);

    return (
        <Snackbar
            autoHideDuration={autoHideDuration}
            message={message.text}
            onClose={handleSnackBarClose}
            open={snackBarOpen}
        />
    );
};
