import * as React from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { v1 as uuidv1 } from "uuid";
import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useUIDispatch, useUIState } from "../../app/uiContext";
import { useAuth } from "../../context/authContext";
import { RegisterForm } from "./RegisterForm";
import { LoginForm } from "./LoginForm";
import { addMessage } from "../alerts/messagesSlice";
import { requestPasswordReset } from "./client";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            aria-labelledby={`simple-tab-${index}`}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            role="tabpanel"
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const RecoverPasswordDialog = ({ email, handleClose, open, setEmail }) => {
    const useStyles = makeStyles((theme) => ({
        form: {
            width: `100%`, // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    }));
    const { mutateAsync, isIdle, isLoading, isSuccess } =
        useMutation(requestPasswordReset);

    const classes = useStyles();
    const validateForm = () => {
        if (!isIdle || isLoading) {
            return false;
        }
        if (typeof email !== `undefined`) {
            const pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            return pattern.test(email);
        }
        return false;
    };
    const handleRecoverSubmit = async (event) => {
        event.preventDefault();
        try {
            await mutateAsync({ email }); /* Fire and forget */
            setEmail(``);
        } catch (error) {
            //
        }
    };

    return (
        <Dialog
            aria-labelledby="form-dialog-title"
            onClose={handleClose}
            open={open}
        >
            <AppBar color="inherit" elevation={24} position="static">
                <DialogTitle className="uppercase" id="form-dialog-title">
                    Recover Password
                </DialogTitle>
            </AppBar>
            <TabPanel index={0} value={0}>
                <DialogContent>
                    {isSuccess ? (
                        <Typography className="pb-16">
                            If an account exists with that email address you
                            should receive a password reset email shortly.
                        </Typography>
                    ) : (
                        <form
                            className={classes.form}
                            noValidate
                            onSubmit={handleRecoverSubmit}
                        >
                            <TextField
                                autoComplete="email"
                                fullWidth
                                id="email"
                                label="Email Address"
                                margin="normal"
                                name="email"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                required
                                value={email}
                                variant="outlined"
                            />
                            <Button
                                className={classes.submit}
                                color="primary"
                                disabled={isLoading || !validateForm()}
                                fullWidth
                                type="submit"
                                variant="contained"
                            >
                                Email me a recovery link
                            </Button>
                        </form>
                    )}
                </DialogContent>
            </TabPanel>
        </Dialog>
    );
};

export const LoginRegisterDialog = () => {
    const {
        loginRegisterOpen: open,
        loginRegisterRedirect,
        loginRegisterTab,
    } = useUIState();
    const uiDispatch = useUIDispatch();
    const handleClose = React.useCallback(() => {
        uiDispatch({ type: `HIDE_LOGIN_REGISTER` });
    }, [uiDispatch]);
    const handleChange = (event, newValue) => {
        uiDispatch({ type: `SHOW_LOGIN_REGISTER_TAB_${newValue}` });
    };
    const { isLoggedIn } = useAuth();
    const [email, setEmail] = React.useState(``);
    const [recoverPassword, setRecoverPassword] = React.useState(false);
    const dispatch = useDispatch();
    const handleAccountCreated = () => {
        dispatch(
            addMessage({
                id: uuidv1(),
                text: `Account created, logging in...`,
                priority: `low`,
            })
        );
    };
    const handleRecoverPasswordClose = () => {
        setRecoverPassword(false);
        handleClose();
    };

    React.useEffect(() => {
        if (open && isLoggedIn()) {
            handleClose();
        }
    }, [handleClose, isLoggedIn, open]);

    return recoverPassword ? (
        <RecoverPasswordDialog
            email={email}
            handleClose={handleRecoverPasswordClose}
            open={open}
            setEmail={setEmail}
        />
    ) : (
        <Dialog
            aria-labelledby="form-dialog-title"
            onClose={handleClose}
            open={open}
        >
            <AppBar color="inherit" elevation={24} position="static">
                <Tabs
                    aria-label="login join tabs"
                    indicatorColor="primary"
                    onChange={handleChange}
                    textColor="primary"
                    value={loginRegisterTab}
                >
                    <Tab
                        aria-controls="tabpanel-0"
                        id="tab-0"
                        label={
                            <DialogTitle id="form-dialog-title">
                                Login
                            </DialogTitle>
                        }
                    />
                    <Tab
                        aria-controls="tabpanel-1"
                        id="tab-1"
                        label={
                            <DialogTitle id="form-dialog-title">
                                Join
                            </DialogTitle>
                        }
                    />
                </Tabs>
            </AppBar>
            <TabPanel index={0} value={loginRegisterTab}>
                <DialogContent>
                    <LoginForm
                        email={email}
                        redirect={loginRegisterRedirect}
                        setEmail={setEmail}
                    />
                    <DialogContentText></DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setRecoverPassword(true);
                        }}
                    >
                        Forgot password?
                    </Button>
                </DialogActions>
            </TabPanel>
            <TabPanel index={1} value={loginRegisterTab}>
                <DialogContent>
                    <RegisterForm
                        email={email}
                        redirect={loginRegisterRedirect}
                        setEmail={setEmail}
                        successCallback={handleAccountCreated}
                    />
                    <DialogContentText></DialogContentText>
                </DialogContent>
            </TabPanel>
        </Dialog>
    );
};
