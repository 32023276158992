import * as React from "react";
import useCallbackStatus from "../../utils/use-callback-status";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../context/authContext";
import { useDispatch } from "react-redux";
import { addMessage } from "../alerts/messagesSlice";
import { v1 as uuidv1 } from "uuid";
import { navigate } from "gatsby";
import { AcceptTerms } from "../../common/AcceptTerms";
import { CountrySelector } from "../../common/CountrySelector";

const useStyles = makeStyles((theme) => ({
    form: {
        width: `100%`, // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

// TODO: Move error messages to higher level language agnostic component
const getMessage = (err) => {
    switch (err.code) {
        case 4:
            return `Please enter a valid email address`;
        default:
            return err.message;
    }
};

export const RegisterForm = ({
    email,
    redirect,
    setEmail,
    successCallback,
}) => {
    const dispatch = useDispatch();
    const { login, register } = useAuth();
    const classes = useStyles();
    const [emailError, setEmailError] = React.useState(``);
    const [firstname, setFirstname] = React.useState(``);
    const [surname, setSurname] = React.useState(``);
    const [country, setCountry] = React.useState(`US`);
    const [password, setPassword] = React.useState(``);
    const [acceptTerms, setAcceptTerms] = React.useState(false);
    const { isPending, isRejected, error, run } = useCallbackStatus();
    const validateForm = () => {
        return (
            acceptTerms &&
            firstname.length > 0 &&
            surname.length > 0 &&
            email.length > 0 &&
            password.length > 0
        );
    };
    function handleSubmit(event) {
        event.preventDefault();
        const { firstname, surname, email, password } = event.target.elements;
        run(
            register({
                firstname: firstname.value,
                surname: surname.value,
                country: country,
                email: email.value,
                password: password.value,
            })
                .then((value) => {
                    successCallback();
                    run(
                        login({
                            email: email.value,
                            password: password.value,
                        }).then(redirect && navigate(redirect))
                    );
                })
                .catch((error) => {
                    dispatch(
                        addMessage({
                            id: uuidv1(),
                            text: getMessage(error),
                            priority: `low`,
                        })
                    );
                })
        );
    }

    React.useEffect(() => {
        if (isRejected && error.code) {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: getMessage(error),
                    priority: `low`,
                })
            );
        }
    }, [error, dispatch, isRejected]);

    return (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
                autoComplete="firstname"
                defaultValue={firstname}
                fullWidth
                id="firstname"
                label="First name"
                margin="normal"
                name="firstname"
                onChange={(e) => {
                    setFirstname(e.target.value);
                }}
                required
                variant="outlined"
            />
            <TextField
                autoComplete="surname"
                defaultValue={surname}
                fullWidth
                id="surname"
                label="Surname"
                margin="normal"
                name="surname"
                onChange={(e) => {
                    setSurname(e.target.value);
                }}
                required
                variant="outlined"
            />
            <CountrySelector
                countryCode={country}
                required
                setCountryCode={(countryCode) => {
                    setCountry(countryCode);
                }}
            />
            <TextField
                autoComplete="email"
                defaultValue={email}
                error={emailError !== ``}
                fullWidth
                helperText={emailError}
                id="email"
                label="Email Address"
                margin="normal"
                name="email"
                onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(``);
                }}
                required
                variant="outlined"
            />
            <TextField
                fullWidth
                id="password"
                label="Password"
                margin="normal"
                name="password"
                onChange={(e) => {
                    setPassword(e.target.value);
                }}
                required
                type="password"
                variant="outlined"
            />
            <AcceptTerms
                checked={acceptTerms}
                handleChecked={(e) => {
                    setAcceptTerms(e.target.checked);
                }}
            />
            <Button
                className={classes.submit}
                color="primary"
                disabled={isPending || !validateForm()}
                fullWidth
                type="submit"
                variant="contained"
            >
                Join
            </Button>
        </form>
    );
};
